<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-pb-none">
        <q-select
          dense
          outlined
          filled
          :options="addedOpt"
          v-model="selJenjang"
          @input="fetch()"
        ></q-select>
      </q-card-section>
      <q-scroll-area
        horizontal
        style="height:calc(100vh - 143px);"
        class="bg-grey-1 q-pt-none"
      >
        <apexchart
          type="bar"
          height="600"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </q-scroll-area>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addedOpt: "",
      selJenjang: "",
      bundle: [],
      values: [],
      labels: [],
      chartOptions: {
        chart: {
          type: "bar",
          id: "vuechart-example",
        },
        title: {
          text: "Pertumbuhan Jumlah Siswa",
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 0,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        theme: {
          mode: "light",
          palette: "palette7",
        },
      },
      series: [
        {
          name: "keluar",
          data: [],
        },
        {
          name: "masuk",
          data: [],
        },
        {
          name: "aktif",
          data: [],
        },
      ],
    };
  },
  async created() {
    this.addedOpt = JSON.parse(JSON.stringify(this.$LIST_JENJANG));
    this.addedOpt.unshift("SEMUA JENJANG");
    this.selJenjang = "SEMUA JENJANG";
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      await this.getYearLabel();
      await this.getyearval();
      await this.tograph();
    },
    tograph() {
      let temp = [];
      let temp2 = [];
      let temp3 = [];
      this.values.forEach((element) => {
        temp.push(parseInt(element.masuk));
        temp2.push(parseInt(element.keluar));
        temp3.push(parseInt(element.aktif));
      });

      let tempSeries = JSON.parse(JSON.stringify(this.series));
      tempSeries[0].data = temp2;
      tempSeries[1].data = temp;
      tempSeries[2].data = temp3;
      this.series = tempSeries;
    },
    async getYearLabel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let tempopt = JSON.parse(JSON.stringify(this.chartOptions));

      tempopt.xaxis.categories = [];
      this.chartOptions = tempopt;

      this.labels = [];
      if (this.selJenjang == "SEMUA JENJANG") {
        await this.$http
          .get("/statistik/grafik/getminmax/", this.$hlp.getHeader())
          .then((resp) => {
            for (
              let i = parseInt(resp.data.start);
              i <= resp.data.finish;
              i++
            ) {
              this.labels.push(i);
              this.chartOptions.xaxis.categories.push(i);
            }
          });
      } else {
        await this.$http
          .get(
            "/statistik/grafik/getminmax/" + this.selJenjang,
            this.$hlp.getHeader()
          )
          .then((resp) => {
            for (
              let i = parseInt(resp.data.start);
              i <= resp.data.finish;
              i++
            ) {
              this.labels.push(i);
              this.chartOptions.xaxis.categories.push(i);
            }
          });
      }
      this.$q.loading.hide();
    },
    async getyearval() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.values = [];
      if (this.selJenjang == "SEMUA JENJANG") {
        for (let i in this.labels) {
          let carier = {};
          await this.$http
            .get(
              "/statistik/grafik/yearin/" + this.labels[i],
              this.$hlp.getHeader()
            )
            .then((resp) => {
              carier.masuk = resp.data.masuk;
            });
          await this.$http
            .get(
              "/statistik/grafik/yearout/" + this.labels[i],
              this.$hlp.getHeader()
            )
            .then((resp) => {
              carier.keluar = resp.data.keluar;
            });
          await this.$http
            .get(
              "/statistik/grafik/yearactive/" + this.labels[i],
              this.$hlp.getHeader()
            )
            .then((resp) => {
              carier.aktif = resp.data.aktif;
            });
          carier.tahun = this.labels[i];
          this.values.push(carier);
        }
      } else {
        for (let i in this.labels) {
          let carier = {};
          await this.$http
            .get(
              "/statistik/grafik/yearin/" +
                this.labels[i] +
                "/" +
                this.selJenjang,
              this.$hlp.getHeader()
            )
            .then((resp) => {
              carier.masuk = resp.data.masuk;
            });
          await this.$http
            .get(
              "/statistik/grafik/yearout/" +
                this.labels[i] +
                "/" +
                this.selJenjang,
              this.$hlp.getHeader()
            )
            .then((resp) => {
              carier.keluar = resp.data.keluar;
            });
          await this.$http
            .get(
              "/statistik/grafik/yearactive/" +
                this.labels[i] +
                "/" +
                this.selJenjang,
              this.$hlp.getHeader()
            )
            .then((resp) => {
              carier.aktif = resp.data.aktif;
            });
          carier.tahun = this.labels[i];
          this.values.push(carier);
        }
      }
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.scss";
</style>
